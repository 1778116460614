.results .filter {
  background: #00af66;
  color: #fff;
  padding: 0 20px;
}

.results .filter h2 {
  color: #ffffff;
  margin-bottom: 20px;
}

.results hr {
  border-top-color: #009b66;
}

.download-item .current-software {
  border-bottom: 1px solid #e8e8e8;
  height: 270px;
}

.download-item .current-software:last-child {
  border: none;
  margin-bottom: 0;
}

.more-downloads {
  margin: 10px 0;
}

.more-downloads .logo {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.anchor-link-icon {
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 2rem;
  padding-left: 0.375em;
  scroll-margin: 50px;
  color: transparent;
  text-shadow: 0 0 0 #39a6da;
}

.anchor-link:hover .anchor-link-icon {
  opacity: 1;
  text-decoration: none;
  color: transparent;
  text-shadow: 0 0 0 #39a6da;
}

.myStuff-panel-list {
  display: flex;
  flex-wrap: wrap;
}

.myStuff-panel-list > div {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.react-datepicker__aria-live {
  display: none;
}

@media print {
  body * {
    visibility: hidden;
  }
  .printable-area,
  .printable-area * {
    visibility: visible;
  }
  .printable-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .no-print {
    display: none;
  }
}

#noBorderPanel {
  .panel.panel-default {
    border-width: 0;
  }

  .panel.panel-default.printable-area:last-child {
    border-bottom-width: 1px;
  }
}

.no-data-message {
  margin: 40px 0 0 20px;
}

.no-products {
  padding-left: 15px;
}

.get-software-link {
  color: #39a6da;
}

.product-tile-link {
  text-decoration: "none";
  color: "inherit";
}

.popover-container {
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 4px;
  position: relative;
  z-index: 10;
}

.popover-container::after {
  content: "";
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.audio-player-icon {
  cursor: pointer;
}

.tag {
  display: inline-block;
  background-color: #f0f0f0;
  color: #333;
  padding: 5px 5px 5px 10px;
  border-radius: 3px;
  margin: 3px 6px 3px 0;
  font-size: 12px;
}

.bulk-control.tag {
  background-color: transparent;
}

.message-icon.remove-tag {
  cursor: pointer;
}

.form-control.input-sm {
  font-size: 14px;
  padding: 0 5px;
  border-radius: 3px;
  height: 27px;
}

.form-inline {
  display: inline-flex;
  align-items: center;
  gap: 3px;
}

.tags-tagForm input {
  flex-grow: 1;
  max-width: 200px;
}

.btn.add-tag {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
}

.btn.add-tag:hover {
  background-color: #0056b3;
}

.button-group-mb {
  display: flex;
  gap: 5px;
}

.button-mb {
  background-color: white;
  color: #616966;
  border: 1px solid #d7d7d7;
  transition: color 0.2s;
  min-height: 38px;
}

.button-mb:hover {
  color: black;
}

.button-mb:active,
.button-mb:focus {
  background-color: #9e9e9e;
  color: white;
  box-shadow: none;
}

.custom-select__control {
  font-family: "Open Sans";
  background-color: white !important;
  color: #616966 !important;
  border: 1px solid #d7d7d7;
  border-radius: 0 !important;
  font-size: 15px;
}

.custom-select__control--is-focused {
  box-shadow: 0 0 0 2px rgba(158, 158, 158, 0.5);
}

.custom-select__placeholder {
  color: #9e9e9e;
}

.custom-select__single-value {
  color: #616966;
}

.custom-select__dropdown-indicator {
  color: #616966;
  transition: color 0.2s;
}

.custom-select__menu {
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  z-index: 10;
}

.custom-select__option {
  background-color: white;
  color: #616966;
  cursor: pointer;
}

.custom-select__option--is-focused {
  background-color: #9e9e9e;
  color: white;
}

.custom-select__option--is-selected {
  background-color: #616966;
  color: white;
}

.custom-select__indicator-separator {
  display: none;
}

.dropdown-filters {
  display: flex;
  gap: 5px;
}

.searchHold {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  font-family: "Open Sans";
}

.mb-searchIcon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #d7d7d7;
}

.search-container {
  position: relative;
  width: 100%;
  padding: 0;
}

.search-box {
  width: 100%;
  min-height: 38px;
  padding-left: 30px;
  padding-right: 30px;
  border: 1px solid #d7d7d7;
  height: 35px;
  box-sizing: border-box;
  font-size: 14px;
  color: #616966;
  transition: border-color 0.2s;
}

.search-box:focus {
  border-color: #9e9e9e;
  box-shadow: 0 0 0 2px rgba(158, 158, 158, 0.5);
  outline: none;
}

.mb-iconStyleClear {
  position: absolute;
  left: 195px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #d7d7d7;
  transition: color 0.2s;
}

.mb-iconStyleClear:hover {
  color: #000;
}

.react-select__control {
  font-family: "Open Sans";
  min-height: 35px;
  min-width: 90px;
  font-size: 14px;
  border: 1px solid #d7d7d7;
  border-left: none !important;
  border-radius: 0 !important;
  box-shadow: none;
  transition: all 0.2s;
}

.react-select__control:hover {
  border-color: #9e9e9e;
}

.react-select__control--is-focused {
  border-color: #9e9e9e;
  box-shadow: 0 0 0 2px rgba(158, 158, 158, 0.5);
}

.react-select__value-container {
  padding: 2px 8px;
}

.react-select__single-value {
  color: #616966;
}

.react-select__placeholder {
  color: #9e9e9e;
}

.react-select__dropdown-indicator {
  color: #616966;
  transition: color 0.2s;
  padding: 4px;
}

.react-select__dropdown-indicator:hover {
  color: #9e9e9e;
}

.react-select__menu {
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  z-index: 10;
}

.react-select__option {
  background-color: white;
  color: #616966;
  cursor: pointer;
  transition:
    background-color 0.2s,
    color 0.2s;
}

.react-select__option:hover {
  background-color: #9e9e9e;
  color: white;
}

.react-select__option--is-focused {
  background-color: #9e9e9e;
  color: white;
}

.react-select__option--is-selected {
  background-color: #616966;
  color: white;
}

.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  font-family: "Open Sans";
}

.controls-left {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
}

.mb-pagination-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.bundle-generator-button {
  flex: 1;
  min-height: 38px;
}

.tag-select-dropdown {
  min-width: 250px;
}

.print-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upload-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-button {
  margin-right: 5px;
  position: relative;
  overflow: hidden;
}
