.pageset {
  -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.175);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.175);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.175);
  margin-bottom: 20px;
  color: #53565a;
  font-size: 13px;
}

.pageset .thumbnail-actions {
  position: relative;
  background: #ffffff;
  padding: 20px 0;
}

.pageset .thumbnail-actions .img-thumbnail {
  border: none;
  background: #ffffff;
}

.pageset .thumbnail-actions .img-thumbnail.show-actions {
  opacity: 0;
}

.pageset .thumbnail-actions .pageset-actions {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 88px;
  width: 100%;
  display: none;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.pageset a {
  text-decoration: none;
}

.pageset a:hover {
  text-decoration: underline;
}

.pageset .details {
  padding: 10px;
  background: #f3f3f3;
  /*height: 300px;
          max-height: 300px;*/
}

.pageset .details h4 {
  color: #53565a;
}

.pageset .details h5 {
  margin-top: 0;
}

.pageset .details .filters {
  border-top: 1px solid #ececec;
  padding: 10px 0;
  font-size: 11.05px;
}

.pageset .details .filters h6 {
  margin: 0;
}

.pageset .details2 {
  padding: 10px 10px 6px;
  background: #e8e8e8;
}

.pageset .details2 .stats {
  margin-right: 22px;
}

.pageset .details2 .stats .icon {
  margin: 2px 5px 0 0;
}

.pageset.saved {
  box-shadow: 0 4px 4px #00af66;
  animation: highlight 2.5s ease-in-out;
  animation-delay: 2s;
}

@media (min-width: 992px) and (max-width: 1199px) {
  #pagesetResults {
    background: url(../assets/images/pagesets/bg_md.gif) 0 0 repeat-y;
  }
}

@media (min-width: 1200px) {
  #pagesetResults {
    background: url(../assets/images/pagesets/bg_lg.gif) 0 0 repeat-y;
  }
}

.paging-status {
  margin-top: 27px;
  margin-right: 20px;
}

.results .filter {
  background: #00af66;
  color: #fff;
  padding: 0 20px;
}

.results .filter h2 {
  color: #ffffff;
  margin-bottom: 20px;
}

.results hr {
  border-top-color: #009b66;
}

.visually-hidden {
  display: none;
}
